(function ($) {
  // @todo finalize sorting and filtering
  Drupal.behaviors.productGrid = {
    attach: function (context, settings) {
      var $grids = $('.product-grid', context);
      var $carouselGrids = $grids.filter('.product-grid--carousel');
      var $carousels = $('.js-product-carousel', $carouselGrids);
      // var sortPlaceholder = '<div class="js-product-grid-sort-placeholder" />';
      var hasQuickshop = $grids.hasClass('product-grid--quickshop');
      var $wrapper = $('.product-grid-wrapper', context);
      var promoImgs = $('.product-promo-img--v1');

      if (promoImgs.length > 0) {
        $(promoImgs[0]).insertBefore($grids.find('.product-grid__content .product-grid__item:nth-child(1)'));
        $(promoImgs[1]).insertBefore($grids.find('.product-grid__content .product-grid__item:nth-child(6)'));
        $(promoImgs[2]).insertAfter($grids.find('.product-grid__content .product-grid__item:nth-child(8)'));
      }

      // Loop through and init the carousels.
      // Carousels might contain variations, so dynamically change the settings before constructing slick
      // @setup - new brand to adjust responsive/dots settings per designs
      $carousels.each(function () {
        var $c = $(this);
        var arrowsDiv = $c.parent().find('.carousel-controls');
        var dotsDiv = $c.parent().find('.carousel-dots');
        var settings = {
          appendArrows: arrowsDiv,
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 3,
          responsive: [
            {
              breakpoint: 960,
              settings: {
                appendDots: dotsDiv,
                arrows: true,
                // dots: true,
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        };
        // slidesToShow override
        var slidesToShowCount = $c.data('slides-to-show');

        if (slidesToShowCount) {
          settings.slidesToShow = slidesToShowCount;
        }

        // Init this carousel with our settings
        $c.slick(settings);

        // On before slide change
        $c.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
          // Remove quickshop:
          if (hasQuickshop && Drupal.behaviors.quickshop) {
            $('.js-quickshop', $wrapper).remove();
          }
        });
      });

      var collectGridProductIds = function ($gridItems) {
        var gridItems = $.makeArray($gridItems);
        var allProductIds = $.map(gridItems, function (cell) {
          return $(cell).data('product-id');
        });

        return allProductIds;
      };
      var sortGrid = function (sortFn) {
        var $allProductCells = $('.js-product-grid-item');
        var allProductIds = collectGridProductIds($allProductCells);
        var allProductData = [];

        _.each(allProductIds, function (prodId, i) {
          allProductData.push(prodcat.data.getProduct(prodId));
        });
        var sortedProductData = _.sortBy(allProductData, sortFn);
        var sortedCells = [];

        _.each(sortedProductData, function (prod, i) {
          if (prod) {
            sortedCells.push($('.js-product-grid-item[data-product-id=' + prod.PRODUCT_ID + ']'));
          }
        });
        var $grid = $('.js-product-grid .product-grid__content');

        $grid.empty();
        _.each(sortedCells, function (cell, i) {
          $grid.append($(cell));
        });
      };

      $(document).on('mpp_sort:selected', function (e, sortFn) {
        var $productGrid = $('.js-product-grid', context);

        sortGrid(sortFn);

        $(this).trigger('product.quickshop.refresh', [
          function () {
            site.util.grids.equalHeightsGrids($productGrid);
          }
        ]);
      });

      $(document).on('mpp_filter:changed', function (e, filters, $mpp) {
        var $c = $mpp.length ? $mpp : context || document;
        var $productGrid = $('.js-product-grid', $c);
        var $filterItems = $('.js-filter-item', $productGrid);
        var filterClass = '';
        // Used for crazy filtering logic
        var arr, cat, opt;
        var cats = {};
        var keys = [];
        var showItems = function ($elements) {
          $elements.removeClass('hidden');
        };
        var hideItems = function ($elements, $allItems) {
          $allItems.removeClass('hidden');
          $elements.addClass('hidden');
        };
        var cartesian = function () {
          var r = [],
            args = Array.from(arguments);

          args.reduceRight(function (cont, factor, i) {
            return function (arr) {
              for (var j = 0, l = factor.length; j < l; j++) {
                var a = arr.slice(); // clone arr

                a[i] = factor[j];
                cont(a);
              }
            };
          }, Array.prototype.push.bind(r))(new Array(args.length));

          return r;
        };

        // If there are no filters selected, then we show all elements
        if (_.isEmpty(filters)) {
          showItems($filterItems);
        }

        // If there are filters, then we only show elements that contain
        // all classes in the array
        else {
          $filterItems.addClass('hidden');

          // Because there are multiple drop-downs for filter categories, we need do
          // a cartesian product, but combine the results with an "or" clause.
          // Example:
          //  skin type = [A, B]
          //  skin concern = [C, D]
          //  .. translates to (.A.C || .A.D || .B.C || .B.D) for the selectors
          for (var i = 0, j = filters.length; i < j; i++) {
            arr = filters[i].split('--');
            if (arr.length != 4) {
              continue;
            }

            cat = arr[2];
            opt = arr[3];
            if (_.isEmpty(cat) || _.isEmpty(opt)) {
              continue;
            }
            if (_.isEmpty(cats[cat])) {
              cats[cat] = new Array();
              keys.push(cat);
            }
            cats[cat].push(arr[0] + '--' + arr[1] + '--' + cat + '--' + opt);
          }

          // I know this conditional sucks, but I can't think of a better way
          // to do it right now.
          var pairs;
          var cl = keys.length;

          if (cl == 1) {
            pairs = cartesian(cats[keys[0]]);
          } else if (cl == 2) {
            pairs = cartesian(cats[keys[0]], cats[keys[1]]);
          } else if (cl == 3) {
            pairs = cartesian(cats[keys[0]], cats[keys[1]], cats[keys[2]]);
          }

          var selectors = new Array();

          for (var k = 0, l = pairs.length; k < l; k++) {
            selectors.push('.' + pairs[k].join('.')); // and
          }

          filterClass = selectors.join(', '); // or
          $(filterClass, $productGrid).removeClass('hidden');

          $(document).trigger('mpp_filter:message', [$mpp, $filterItems.not('.hidden').length]);
        }

        $(this).trigger('product.quickshop.refresh', [
          function () {
            site.util.grids.equalHeightsGrids($productGrid);
          }
        ]);
      });

      // Filter event:
      //       $grids.on('productGrid.filter', function(event, prodsToShow, sort) {
      //         var $items = $('.js-product-grid-item', this);
      //         var $filteredItems = $items.filter(function() {
      //           return _.contains(prodsToShow, $(this).data('product-id'));
      //         });
      //         var $grid = $(this);
      //         var $container = $items.first().parent();
      //
      //         // First, undo any previous sorting we may have done:
      //         _resetFilterSort($grid);
      //
      //         // (Optionally) Sort:
      //         if (sort) {
      //           // Put a placeholder before the items we're going to sort so we can
      //           // un-sort them later (_resetFilterSort).
      //           $filteredItems.before(sortPlaceholder);
      //           // Reverse the array because we're prepending. Appending also works,
      //           // but this way we can target :first-child in css to get the primary
      //           // result in regimens.
      //           _.each(_.clone(prodsToShow).reverse(), function(id) {
      //             var $item = $filteredItems.filter('[data-product-id="' + id + '"]');
      //             $item.prependTo($container);
      //           });
      //         }
      //
      //         // Filter:
      //         $items.addClass('hidden');
      //         $filteredItems.removeClass('hidden');
      //
      //         $(this).trigger('grid.reflow');
      //       });

      // Reset filter event:
      //       $grids.on('productGrid.showAll', function() {
      //         _resetFilterSort($(this));
      //         $('.js-product-grid-item', this).removeClass('hidden');
      //
      //         $(this).trigger('grid.reflow');
      //       });
    }
  };

  //   function _resetFilterSort($grid) {
  //     var $items = $('.js-product-grid-item', $grid);
  //     $('.js-product-grid-sort-placeholder', $grid).each(function() {
  //       var id = $(this).data('placeholder-product-id');
  //       var $item = $items.filter('[data-product-id="' + id + '"]');
  //       $(this).after($item).remove();
  //     });
  //   }
})(jQuery);
